import '../../../service/tournaments/fast-tournament.js'
import {filter} from 'rxjs/operators';
(function () {
  'use strict';

  controller.$inject = ['$scope', 'fastTournament'];

  function controller($scope, _fastTournament) {
    $scope.tournaments = [];
    $scope.preloader = true;
    $scope.isShowMore = false;

    let limit = 10;

    const getHistory = () => {
      _fastTournament.getHistory(limit).then((answer) => {
        $scope.tournaments = answer.result;
        $scope.isShowMore = answer.total_count > limit;
        $scope.$apply();
      });
    };

    getHistory();

    const subscription = _fastTournament.preloader$
      .pipe(filter((answer) => answer.action === 'historyPreloader'))
      .subscribe((answer) => {
        $scope.preloader = answer.preloader;
      });

    $scope.nextPage = () => {
      limit += 10;
      getHistory();
    };

    this.$onDestroy = () => {
      subscription.unsubscribe();
    };
  }

  new Controller('fastTournamentsHistory', controller, {});
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanFastTournamentsHistory${i}`, 'fastTournamentsHistory');
  });
})();
