(function () {
  'use strict';

  controller.$inject = [];

  function controller() {
    class Store {
      constructor() {
        this.featured = {};
        this.live = {};
        this.upNext = {};
        this.soon = [];
      }

      add(tournament) {
        if (tournament.type === 'live') {
          this.live = tournament;
          return;
        }
        if (tournament.type === 'featured') {
          this.featured = tournament;
          return;
        }
        if (tournament.type === 'upNext') {
          this.upNext = tournament;
          return;
        }
        this.soon.push(tournament);
      }

      changeOptInStatus(type, index) {
        index >= 0 ? (this[type][index].optIn = true) : (this[type].optIn = true);
      }

      updateOptIn(tournamentId, type) {
        let indexOfItem;
        if (type === 'soon') {
          indexOfItem = this.soon.findIndex((item, index) => {
            return tournamentId === item.tournamentEventId;
          });
        }
        this.changeOptInStatus(type, indexOfItem);
      }
    }

    return {
      createStore() {
        return new Store();
      },
    };
  }

  app.factory('fastTournamentStore', controller);
})();
