import '../../../service/rx/system$.js'
import '../../../service/domain/user.js'
import '../../../service/tournaments/fast-tournament.js'
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

(function () {
  'use strict';

  controller.$inject = ['$scope', 'system$', 'user', 'fastTournament'];

  function controller($scope, _system$, _user, _fastTournament) {
    const destroy$ = new Subject();
    const Tabs = { SCHEDULE: 'schedule', HISTORY: 'history', RULES: 'rules' };
    $scope.tournaments = {};
    $scope.preloader = true;
    $scope.currentTab = Tabs.SCHEDULE;

    function setTabsToScope() {
      if (_user.status) {
        $scope.tabs = [{ alias: Tabs.SCHEDULE }, { alias: Tabs.HISTORY }, { alias: Tabs.RULES }];
        return;
      }
      $scope.tabs = [{ alias: Tabs.SCHEDULE }, { alias: Tabs.RULES }];
    }

    $scope.setCurrentTab = (tab) => ($scope.currentTab = tab);

    _fastTournament.fastTournamentStore$.pipe(takeUntil(destroy$)).subscribe((answer) => {
      $scope.tournaments = { ...answer };
    });

    _fastTournament.preloader$
      .pipe(
        takeUntil(destroy$),
        filter((answer) => answer.action === 'listPreloader')
      )
      .subscribe((answer) => {
        $scope.preloader = answer.preloader;
      });

    _fastTournament.getTournamentList().then((list) => {
      $scope.tournaments = { ...list };
    });

    setTabsToScope();

    _system$
      .pipe(
        takeUntil(destroy$),
        filter((message) => message.action === 'statusChanged')
      )
      .subscribe(() => {
        setTabsToScope();
      });

    this.$onDestroy = () => {
      destroy$.next();
      destroy$.unsubscribe();
    };
  }

  new Controller('fastTournamentsPage', controller, {});
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanFastTournamentsPage${i}`, 'fastTournamentsPage');
  });
})();
