import '../../service/domain/user.js';
import '../../service/domain/achievement.js';
import '../../service/configs/config.js';
import { gsap, SteppedEase, TimelineMax, TweenMax } from 'gsap';

(function () {
  'use strict';

  const component = { name: 'lindaCup' };

  controller.$inject = ['$scope', '$document', 'user', 'achievement', '$q', 'config'];

  function controller($scope, $document, _user, _achievement, $q, _config) {
    $scope.preloader = true;
    $scope.cup = {
      prizeCount: 0,
      goal: 0,
      currentCount: 0,
      currentPtc: 0,
    };

    const achievementDefer = $q.defer();
    _achievement
      .list({ category: 'cup', count: 1, order: '' })
      .then((a) => {
        $scope.cup.prizeCount = a.result[0].prize;
        $scope.cup.goal = a.result[0].progress[1];
        $scope.cup.currentCount = a.result[0].progress[0];
        $scope.cup.currentPtc = a.result[0].progress[2];

        achievementDefer.resolve();

      })
      .catch((e) => {
        achievementDefer.reject();
      })
      .finally(() => {});

    // SELECTORS

    const cupWrap = $document[0].querySelector('.cup__wrap');
    const cupBack = cupWrap.querySelector('.cup__back');
    const cupFront = cupWrap.querySelector('.cup__front-img');
    const cupProgress = cupWrap.querySelector('.cup__front');
    const cupMark = cupWrap.querySelector('.cup-mark.is-current');

    // ANIMATION PROGRESS

    const progressDuration = 2;

    function calcProgress(x) {
      const deltaBottom = 0.343 * progressDuration;
      const progressLine = x * progressDuration * 0.01 * 0.657;
      const result = deltaBottom + progressLine;
      return result;
    }

    const progressCupConfig = {
      height: '81%',
      ease: SteppedEase.config(100),
    };
    const progressMarkConfig = {
      bottom: '81%',
      ease: SteppedEase.config(100),
    };

    const progressTimeLine = new TimelineMax({ paused: true });
    const progressCupTween = TweenMax.to(cupProgress, progressDuration, progressCupConfig);
    const progressMarkTween = TweenMax.to(cupMark, progressDuration, progressMarkConfig);

    progressTimeLine.add(progressCupTween, 0);
    progressTimeLine.add(progressMarkTween, 0);

    // ANIMATION TWIST

    const twistDuration = 1;
    const twistSegment = 1000;
    const twistConfig = {
      reversed: true,
      backgroundPosition: '-10800px',
      ease: SteppedEase.config(30),
    };

    const twistTimeLine = new TimelineMax({ paused: true });
    const twistBackTween = TweenMax.to(cupBack, twistDuration, twistConfig);
    const twistFrontTween = TweenMax.to(cupFront, twistDuration, twistConfig);

    twistTimeLine.add(twistBackTween, 0);
    twistTimeLine.add(twistFrontTween, 0);
    twistTimeLine.progress(twistDuration * 0.5);

    const twistCup = (e) => {
      const deltaX = (e.view.innerWidth - twistSegment) * 0.5,
        mousePosition = (e.pageX - deltaX) / twistSegment;

      if (0 < mousePosition && mousePosition <= 1) {
        twistTimeLine.progress(mousePosition);
      }
    };

    // LOAD IMAGES

    const cupGoldImageDefer = $q.defer();
    const cupGoldImage = new Image();
    cupGoldImage.onload = () => {
      cupGoldImageDefer.resolve();
    };
    cupGoldImage.onerror = () => {
      cupGoldImageDefer.reject();
    };
    cupGoldImage.src = `${_config.cdn}/${_config.siteName}/cup-page/Cup_gold.png`; //'https://joxi.imgsrcdata.com/rabona/cup-page/Cup_gold.png';

    const cupGlassImageDefer = $q.defer();
    const cupGlassImage = new Image();
    cupGlassImage.onload = () => {
      cupGlassImageDefer.resolve();
    };
    cupGlassImage.onerror = () => {
      cupGoldImageDefer.reject();
    };
    cupGlassImage.src = `${_config.cdn}/${_config.siteName}/cup-page/Cup_glass_.png`; //'https://joxi.imgsrcdata.com/rabona/cup-page/Cup_glass_.png';

    // PROMISE
    $q.all([achievementDefer.promise, cupGoldImageDefer.promise, cupGlassImageDefer.promise])
      .then((a) => {
        const progressLabel = calcProgress($scope.cup.currentPtc);
        progressTimeLine.addLabel('progressCurrent', progressLabel);
        progressTimeLine.addPause('progressCurrent');

        cupWrap.classList.add('linda-img-loaded');
        progressTimeLine.play(0);
      })
      .catch((e) => {})
      .finally(() => {
        $scope.preloader = false;
      });

    this.$onInit = () => window.addEventListener('mousemove', twistCup);
    this.$onDestroy = () => window.removeEventListener('mousemove', twistCup);
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
  });
})();
