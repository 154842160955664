import '../../../service/tournaments/fast-tournament.js'
import '../../../service/domain/user.js'
(function () {
  'use strict';

  controller.$inject = ['$scope', 'fastTournament', 'user'];

  function controller($scope, _fastTournament, user) {
    $scope.data = [];
    $scope.currentPlayerId = user?.profile?.account_id;

    this.$onChanges = () => {
      _fastTournament
        .getLeaderBoardList(this.tournamentId)
        .then((answer) => {
          $scope.data = answer.result;
        })
        .catch(() => {})
        .finally(() => {});
    };
  }

  new Controller('fastTournamentsHistoryLeaderboard', controller, { tournamentId: '<' });
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanFastTournamentsHistoryLeaderboard${i}`, 'fastTournamentsHistoryLeaderboard');
  });
})();
