(function () {
  'use strict';

  controller.$inject = [];

  function controller() {
    let tournamentNewFields = {};
    let testTimestamp = window.localStorage.getItem('spin-rally');
    let currentTimestamp;
    const tenMinutesInTimestamp = 10 * 60 * 1000;

    const checkIsUpNext = (tournamentStartTime) => {
      let delta = tournamentStartTime - currentTimestamp;
      return delta < tenMinutesInTimestamp && delta >= 0;
    };

    const checkIsLive = (tournamentStart, tournamentEnd) =>
      currentTimestamp >= tournamentStart && currentTimestamp < tournamentEnd;

    const checkTournamentType = (item) => {
      if (checkIsLive(item.startTime, item.endTime)) {
        return 'live';
      }
      if (item.featured) {
        return 'featured';
      }
      if (checkIsUpNext(item.startTime)) {
        return 'upNext';
      }
      return 'soon';
    };

    let createMapper = (item) => {
      currentTimestamp = testTimestamp ? +new Date(testTimestamp) : +new Date();
      tournamentNewFields.show = true;
      tournamentNewFields.type = checkTournamentType(item);
      if (tournamentNewFields.type === 'live') {
        tournamentNewFields.countdown = item.endTime - currentTimestamp > 0 ? item.endTime - currentTimestamp : null;
      }
      if (tournamentNewFields.type === 'upNext') {
        tournamentNewFields.countdown = item.startTime - currentTimestamp;
      }
      tournamentNewFields.isToday =
        new Date(currentTimestamp).toLocaleDateString() === new Date(item.startTime).toLocaleDateString();
      tournamentNewFields.prizePool = item.prizes.reduce((a, i) => i.amount + a, 0);
      tournamentNewFields.isStartsSoon = new Date(currentTimestamp).getTime() + 10 * 60 * 1000 > item.startTime;
      tournamentNewFields.durationInMinutes = (item.endTime - item.startTime) / (1000 * 60);

      return { ...item, ...tournamentNewFields };
    };

    return {
      createMapper,
    };
  }

  app.factory('fastTournamentMapper', controller);
})();
