import '../../../service/domain/shop.js'
import '../../../service/rx/shop$.js'
import {filter} from 'rxjs/operators';
(function () {
  controller.$inject = ['$scope', 'shop', 'shop$'];

  function controller($scope, _shop, _shop$) {
    $scope.collection = [];
    $scope.item = {};
    $scope.preloader = false;

    const options = {
      category: 'exchange',
    };

    function getCollection(o) {
      $scope.preloader = true;
      _shop
        .list(o)
        .then(
          (a) => {
            $scope.collection = a.result;
            $scope.item = $scope.collection[0] || {};
          },
          (a) => {}
        )
        .finally(() => {
          $scope.preloader = false;
        });
    }

    _shop$
      .pipe(filter((item) => item.action === 'buy'))
      .subscribe((item) => {
        if ($scope.collection.find((i) => item.goods.id === i.id)) getCollection(options);
      });

    this.$onChanges = () => {
      options.count = this.count || 25;

      getCollection(options);
    };
  }

  new Controller('shopExchangeBox', controller, {
    count: '<',
  });
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanShopExchangeBox${i}`, 'shopExchangeBox');
  });
})();
