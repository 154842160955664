var $_CONFIG = $_CONFIG || {};
$_CONFIG.gsapVersion = '3';
$_CONFIG.sportId = 'sportaza';

$_CONFIG.sportWidgetSkinName = 'sportaza';

$_CONFIG.sport = true;
$_CONFIG.sportVersion = 3;
$_CONFIG.sportFixedTop = 120;
$_CONFIG.sportInsufficientBalanceCallback = true;

$_CONFIG.useSvgSprite = false;
$_CONFIG.scripts = $_CONFIG.scripts || [];

$_CONFIG.domain = 'https://sportaza.devv2.space';

$_CONFIG.siteDomain = 'https://sportaza.com';
$_CONFIG.cdn = '/joxi';

$_CONFIG.pwa = true;
$_CONFIG.appName = 'Sportaza';
$_CONFIG.themeColor = '#1E2737';

$_CONFIG.siteName = 'sportaza';
$_CONFIG.mobile = false;

$_CONFIG.redirectToNotFound = false;

$_CONFIG.libphonenumber = true;
$_CONFIG.googleAnalytics = { appId: 'UA-191552411-1 ', trackAs: 'Sportaza ' };
$_CONFIG.symplify = true;

//- ПЛЕЙСИ ДЛЯ БАНЕРІВ І СЛАЙДЕРІВ
//- в шаблоні вказувати саме об'єкт конфіга.
$_CONFIG.places = {
  banner: {
    login: `'login_banner'`,
    registration: {
      casino: `'registration_bonus_casino'`,
      sport: `'registration_bonus_sport'`,
      nobonus: `'registration_bonus_no'`,
      welcome_crypto: `'registration_bonus_crypto'`,
    },
    home1: `'sport_main_banner'`,
    home2: `'paynplay_pay'`,
    home3: `'sport_promotion'`,
    home4: `'live_promotion'`,
    home5: `'casino_promotion'`,
    home6: `'collection_main_banner'`,
    entrance_bnr1: `'entrance_1'`,
    entrance_bnr2: `'gamehall'`,
    entrance_bnr3_1: `'entrance_3_1'`,
    entrance_bnr3_2: `'entrance_3_2'`,
    entrance_bnr3_3: `'entrance_3_3'`,
    entrance_tournament: `'entrance_tournament'`,
    inframe_banner1_1: `'inframe_banner1'`,
    inframe_banner1_2: `'inframe_banner2'`,
    inframe_banner1_3: `'inframe_banner3'`,
    entrance_bnr3: `'entrance_3'`,
    entrance_bnr4: `'entrance_4'`,
    entrance_bnr5: `'entrance_5'`,
    gamehall_challenges: `'gamehall_challenges'`,
    crypto_banner: `'crypto_banner'`,
  },
  slider: {
    gamehall1: `'gamehall'`, //for video banner in game hall page
    gamehall_slider: `'gamehall_slider'`,
  },
  promo: {
    registration: `'welcome_bonus'`,
    home1: `'sport_main'`,
    home2: `'casino_main'`,
    home3: `'g.casino'`,
    card_guide: `'collection_guide'`,
  },
  icons: {
    footer1: `'footer_pay'`,
    paynplay: `'paynplay_pay'`,
  },
};

$_CONFIG.banners = {
  bottomRight: {
    url: 'https://joxi.imgsrcdata.com/rabona/sports-banner-frame/{lang}/right-banner1/{lang}1.jpg',
    link: 'https://rabona.com/{lang}/champions-cup',
  },
};

$_CONFIG.sportCategory = {
  football: 'football',
  tennis: 'tennis',
  basketball: 'basketball',
  hockey: 'hockey',
};

$_CONFIG.sportBanners = {
  topCenter: {
    iframe: `/sport-banners-slider/`,
    options: {
      pages: ['prelive'],
      height: '190px',
    },
  },
  aboveHighlights: {
    iframe: '/sport-banners-slider/live-stream/',
    options: {
      pages: ['prelive'],
      height: '290px',
    },
  },
  bottomLeft: {
    iframe: '/sport-banners-slider/sport-leaderboard/',
    options: {
      pages: ['prelive'],
      height: '168px',
    },
  },
  bottomRight: {
    iframe: `/joxi/js/crab/sdk/views/widget-sport.html?build=${Date.now()}`,
    options: {
      height: '486px',
    },
  },
};


if (typeof window !== 'undefined') {
  window.$_REGISTRATION = {};
  window.$_REGISTRATION.promotions = {
    category: 'registration_promo',
  };
}

export { $_CONFIG };
