//- All property styles you can check on this page:
//- https://sb2widgetsstatic-altenar2-stage.biahosted.com/docs/?path=/docs/widgets-tokens--page
//- On this page you can try constructor styles:
//- https://sb2widgetsstatic-altenar2-stage.biahosted.com/docs/?path=/story/widgets-wtabbedeventsbytype--live-now
const $_ALTENARCONFIG = {};

let WLiveNow = {
  LiveNow: {
    "borderWidth": 1,
    "borderColor": "#E9EBF7",
    "borderStyle": "solid",
    "borderRadius": 0,
    "background": "#fff",
    "paddingVertical": 10,
    "paddingHorizontal": 16
  },
  LiveNowHeader: {
    color: "#05207B",
    borderWidth: 0,
    iconWidth : 0,
    iconHeight : 0,
    textTransform: "uppercase",
    background: "#fff",
    font: ["Roboto", 18, "italic", 900, "40px"],
  },
  LiveNowTab: {
    "borderWidth": 0,
    "borderColor": "unset",
    "borderStyle": "none",
    "font": [
      "Montserrat,sans-serif",
      16,
      "normal",
      700,
      "14px"
    ],
    "background": "#fff",
    "color": "#05207B",
    "paddingHorizontal": 12,
    "paddingVertical": 10,
    "borderRadius": 0,
    "spacing": 16,
    "textTransform": "uppercase"
  },
  LiveNowTabSelected: {
    "font": [
      "Montserrat,sans-serif",
      16,
      "normal",
      700,
      "14px"
    ],
    "indicatorColor": "#fff",
    "borderColor": "#fff",
    "color": "#fff",
    "background": "#05207B",
    "textTransform": "uppercase"
  },
}
let WHighlights= {
  Highlights: {
    "borderWidth": 1,
    "borderColor": "#E9EBF7",
    "borderStyle": "solid",
    "borderRadius": 0,
    "background": "#fff",
    "paddingVertical": 10,
    "paddingHorizontal": 16
  },
  HighlightsHeader: {
    color: "#05207B",
    borderWidth: 0,
    iconWidth : 0,
    iconHeight : 0,
    LiveIndicator: 0,
    textTransform: "uppercase",
    background: "#fff",
    font: ["Roboto", 18, "italic", 900, "40px"],
  },
  HighlightsTab: {
    "borderWidth": 0,
    "borderColor": "unset",
    "borderStyle": "none",
    "font": [
      "Montserrat,sans-serif",
      16,
      "normal",
      700,
      "14px"
    ],
    "background": "#fff",
    "color": "#05207B",
    "paddingHorizontal": 12,
    "paddingVertical": 10,
    "borderRadius": 0,
    "spacing": 16,
    "textTransform": "uppercase"
  },
  HighlightsTabSelected: {
    "font": [
      "Montserrat,sans-serif",
      16,
      "normal",
      700,
      "14px"
    ],
    "indicatorColor": "#fff",
    "borderColor": "#fff",
    "color": "#fff",
    "background": "#05207B",
    "textTransform": "uppercase"
  },
}
let defaultSettings = {
  "OddBox": {
    "oddFont": ["Roboto", 14, "normal", 900, "normal"],
    "labelFont": ["Roboto", 14, "normal", 400, "normal"],
    "oddColor": "#05207B",
    "background" : "transparent",
    "labelColor": "rgba(0, 0, 0, 0.54)",
    borderRadius: 0
  },
  "OddBoxSelected": {
    "borderColor": "#fff",
    "background": "#05207B",
    "oddColor": "#FFFFFF",
    "labelColor": "#FFFFFF"
  },
  "MarketBox": {
    "width": "100%",
    "spacing": 8
  },
  "EventBox": {
    "borderWidth": 1,
    "borderColor": "#E9EBF7",
    "borderStyle": "solid",
    "borderRadius": 0,
    "background": "#FFFFFF",
    "paddingVertical": 12,
    "paddingHorizontal": 12,
    "liveTimeFont": [
      "Montserrat",
      14,
      "normal",
      500,
      "21px"
    ],
    "dateTimeFont": [
      "Montserrat",
      12,
      "normal",
      600,
      "20px"
    ],
    "categoryChampionshipFont": [
      "Montserrat",
      12,
      "normal",
      600,
      "20px"
    ],
    "competitorFont": [
      "Montserrat",
      14,
      "normal",
      700,
      "21px"
    ],
    "scoreFont": [
      "Montserrat",
      14,
      "normal",
      700,
      "21px"
    ],
    "liveTimeColor": "#65697B",
    "dateTimeColor": "rgba(0, 0, 0, 0.87)",
    "categoryChampionshipColor": "rgba(0, 0, 0, 0.54)",
    "competitorColor": "rgba(0, 0, 0, 0.87)",
    "scoreColor": "rgba(0, 0, 0, 0.87)"
  },
  "EventList": {
    "rowSpacing": 8,
    "columnSpacing": 16
  },
  OddChangeIndicator: {
    size : 6,
    upColor : "#4CAF50",
    downColor : "#F44336",
  }
};

Object.assign(WLiveNow, defaultSettings);
Object.assign(WHighlights, defaultSettings);

let carousel = {
  "OddBox": {
    "borderWidth": 1,
    "borderColor": "#E9EBF7",
    "borderRadius": 0,
    "variant": 0,
    "spacing": 4,
    "background": "#fff",
    "paddingVertical": 8,
    "paddingHorizontal": 11,
    "oddFont": [
      "Roboto",
      17,
      "normal",
      700,
      "normal"
    ],
    "labelFont": [
      "Roboto",
      17,
      "normal",
      400,
      "normal"
    ],
    "oddColor": "rgba(0, 0, 0, 0.87)",
    "labelColor": "rgba(0, 0, 0, 0.54)",
    "arrowUp": [
      24,
      24,
      "#4CAF50"
    ],
    "arrowDown": [
      24,
      24,
      "#F44336"
    ]
  },
  "OddBoxSelected": {
    "borderColor": "#05207B",
    "background": "#05207B",
    "oddColor": "#E0E0E0",
    "labelColor": "#E0E0E0"
  },
  "BannerEventBox": {
    "variant": 0,
    "width": 320,
    "borderWidth": 1,
    "borderColor": "#E0E0E0",
    "borderRadius": 0,
    "background": "#fff",
    "paddingVertical": 20,
    "paddingHorizontal": 20,
    "timeFont": [
      "Roboto",
      16,
      "normal",
      700,
      "24px"
    ],
    "dateFont": [
      "Roboto",
      15,
      "normal",
      400,
      "20px"
    ],
    "championshipFont": [
      "Roboto",
      16,
      "normal",
      400,
      "20px"
    ],
    "competitorFont": [
      "Roboto",
      16,
      "normal",
      500,
      "24px"
    ],
    "messageBodyFont": [
      "Roboto",
      16,
      "normal",
      500,
      "24px"
    ],
    "messageHeaderFont": [
      "Roboto",
      16,
      "normal",
      500,
      "24px"
    ],
    "timeColor": "rgba(0, 0, 0, 0.87)",
    "dateColor": "rgba(0, 0, 0, 0.54)",
    "championshipColor": "rgba(0, 0, 0, 0.54)",
    "competitorColor": "rgba(0, 0, 0, 0.87)",
    "messageHeaderColor": "#15C187",
    "messageBodyColor": "rgba(0, 0, 0, 0.87)",
    "dividerColor": "rgba(0, 0, 0, 0.12)",
    "dividerWidth": 1,
    "dividerHeight": "85%"
  },
  "MarketBox": {
    "font": [
      "Roboto",
      12,
      "normal",
      500,
      "21px"
    ],
    "color": "rgba(0, 0, 0, 0.54)",
    "spacing": 8
  },
  "CompetitorLogo": {
    "height": 64,
    "width": 64,
    "background": "#fff",
    "borderRadius": "50%",
    "borderColor": "#E9EBF7",
    "borderWidth": 2,
    "offset": -8
  },
  "EventsCarousel": {
    "borderWidth": 0,
    "borderColor": "unset",
    "borderRadius": 0,
    "width": "unset",
    "paddingHorizontal": 0,
    "paddingVertical": 24,
    "spacing": 12
  },
  "EventsCarouselControls": {
    "borderWidth": 1,
    "borderColor": "#E9EBF7",
    "borderRadius": 8,
    "dotBackground": "#D5D5D5",
    "selectedDotBackground": "#D5D5D5",
    "background": "#FFFFFF",
    "color": "rgba(0, 0, 0, 0.87)",
    "height": 224,
    "width": 32
  },
  EventsCarouselControlsHovered: {
    background: "#FFFFFF",
    color: "rgba(0, 0, 0, 0.87)",
  }
};
$_ALTENARCONFIG.widget = {
  tokens: {
    WLiveNow,
    WHighlights
  },
  carousel
};

export { $_ALTENARCONFIG };
