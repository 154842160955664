import '../../../service/domain/tournament.js';
(function () {
  'use strict';

  const component = { name: 'lindaFastTournamentsRules' };

  controller.$inject = ['$scope', 'tournament'];

  function controller($scope, _tournament) {}

  app.component(component.name, {
    bindings: {
      tournament: '<',
    },
    controller: controller,
    template: app.getTU(component.name),
  });
})();
