(function () {
  controller.$inject = ['$scope', '$interval'];

  function controller($scope, $interval) {
    let start, end, timer, cb, time, currentTimestamp;

    $scope.time = 0;
    $scope.progress = 0;
    $scope.duration = 0;

    const setTimer = () => {
      timer = $interval(() => {
        if (start + time >= end) {
          $interval.cancel(timer);
          return;
        }
        time++;
        $scope.progress = (time / ($scope.duration / 1000)) * 100;
        $scope.time = time;
      }, 1000);
    };

    this.$onChanges = () => {
      $scope.end = this.end;
      $scope.duration = this.end - this.start;

      cb = this.callback;
      currentTimestamp = +new Date();
      end = +(this.end / 1000).toFixed();
      start = +(this.start / 1000).toFixed();
      time = +((currentTimestamp - this.start) / 1000).toFixed() || 0;

      if (currentTimestamp < this.end) {
        setTimer();
        return;
      }
      if (typeof cb === 'function') {
        cb();
      }
    };

    this.$onDestroy = () => {
      if (timer) {
        $interval.cancel(timer);
      }
    };
  }

  new Controller('countup', controller, { start: '<', end: '<', callback: '<' });
})();

(function () {
  [1, 2, 3].forEach((i) => {
    new Batman(`batmanCountup${i}`, 'countup');
  });
})();
