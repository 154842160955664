const router = [
  {
    name: 'app.root',
    abstract: true,
    template: '<linda-app linda-zendesk></linda-app>',
  },
  {
    name: 'app.root.h1',
    abstract: true,
    template: '<linda-view-layer-one class="has-header linda-layer"></linda-view-layer-one>',
  },
  {
    name: 'app.root.h1.f1',
    abstract: true,
    template: '<linda-view-layer-two class="linda-layer"></linda-view-layer-two>',
  },
  {
    name: 'app.root.paymentcb',
    url: '/payment/:act?order_id&identifier',
    template: '<linda-payment-callback></linda-payment-callback>',
  },

  {
    name: 'app.root.withdraw-paymentcb-success',
    url: '/withdraw-payment/success',
    template: '<linda-withdrawal-payment-callback></linda-withdrawal-payment-callback>',
    params: {
      status: 'success',
    },
  },
  {
    name: 'app.root.withdraw-paymentcb-fail',
    url: '/withdraw-payment/fail',
    template: '<linda-withdrawal-payment-callback></linda-withdrawal-payment-callback>',
    params: {
      status: 'fail',
    },
  },
  {
    name: 'app.root.h1.f1.home',
    url: '/',
    template: '<linda-homepage class="is-overflow-hid" scroll-to-top></linda-homepage>',
    params: {
      pageName: 'mainPage',
    },
  },

  {
    name: 'app.root.h1.f1.404',
    url: '/404',
    template: '<not-found class="full-screen" scroll-to-top></not-found>',
  },
  {
    name: 'app.root.h1.f1.paynplay',
    url: '/fast-deposit',
    template: '<linda-zimpler-paynplay-page class="paynplay-page"></linda-zimpler-paynplay-page>',
    params: {
      theme: 'clean is-paynplay',
    },
  },
  {
    name: 'app.root.h1.info',
    abstract: true,
    template: '<linda-view-layer-six scroll-to-top></linda-view-layer-six>',
  },
  {
    name: 'app.root.h1.info.sitemap',
    url: '/sitemap',
    template: '<linda-sitemap scroll-to-top></linda-sitemap>',
    params: {
      name: 'sitemap',
    },
  },
  {
    name: 'app.root.h1.info.privacy-policy',
    url: '/privacy-policy',
    template: '<linda-info-page scroll-to-top></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'privacy-policy',
    },
  },
  {
    name: 'app.root.h1.info.cookies-policy',
    url: '/cookies-policy',
    template: '<linda-info-page scroll-to-top></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'cookies-policy',
    },
  },
  {
    name: 'app.root.h1.info.about',
    url: '/about-us',
    template: '<linda-info-page scroll-to-top></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'about',
    },
  },
  {
    name: 'app.root.h1.info.contact',
    url: '/contact-us',
    template: '<linda-info-page scroll-to-top></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'contact-us',
    },
  },
  {
    name: 'app.root.h1.info.responsible-gaming',
    url: '/responsible-gaming',
    template: '<linda-info-page scroll-to-top></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'responsible-gaming',
    },
  },
  {
    name: 'app.root.h1.info.terms',
    url: '/rules',
    template: '<linda-info-page scroll-to-top></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'rules',
    },
  },
  {
    name: 'app.root.h1.info.faq',
    url: '/faq',
    template: '<linda-info-page scroll-to-top></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'faq',
    },
  },
  {
    name: 'app.root.h1.f1.payments',
    url: '/payments',
    template: '<batman-banking-page1 scroll-to-top></batman-banking-page1>',
  },

  {
    name: 'app.root.h1.f1.shop',
    url: '/shop',
    template: '<linda-shop-page scroll-to-top></linda-shop-page>',
  },
  {
    name: 'app.root.h1.f1.collection',
    abstract: true,
    template: '<ui-view></ui-view>',
  },
  {
    name: 'app.root.h1.f1.collection.list',
    url: '/collections',
    template: '<batman-sport-team-list1 scroll-to-top></batman-sport-team-list1>',
  },
  {
    name: 'app.root.h1.f1.collection.item',
    url: '/collections/:name',
    template: '<linda-sport-team-page scroll-to-top></linda-sport-team-page>',
  },

  {
    name: 'app.root.h1.f1.collection.guide',
    url: '/collections-terms',
    template: '<linda-view-layer-five scroll-to-top></linda-view-layer-five>',
  },
  {
    name: 'app.root.h1.f1.vip',
    url: '/vip',
    template: '<linda-vip scroll-to-top></linda-vip>',
  },
  {
    name: 'app.root.h1.f1.cup',
    private: true,
    url: '/champions-cup',
    template: '<linda-cup-page class="full-screen" scroll-to-top></linda-cup-page>',
  },
  {
    name: 'app.root.h1.f1.sportpage',
    abstract: true,
    template: '<linda-sport-page scroll-to-top></linda-sport-page>',
  },
  {
    name: 'app.root.h1.f1.sportpage.prelive',
    url: '/sport?sportids&catids&champids&tabs&eventid&betslipoddids',
    template: '',
    params: {
      page: 'prelive',
    },
  },
  {
    name: 'app.root.h1.f1.sportpage.live',
    url: '/live?sportids&catids&champids&tabs&eventid&betslipoddids',
    template: '',
    params: {
      page: 'live',
    },
  },
  {
    name: 'app.root.h1.f1.sportpage.virtual',
    url: '/virtual?sportids&catids&champids&tabs&eventid&betslipoddids',
    template: '',
    params: {
      page: 'vflm',
    },
  },
  {
    name: 'app.root.h1.f1.horse_racing',
    abstract: true,
    template: '<linda-view-layer-seven scroll-to-top></linda-view-layer-seven>',
  },
  {
    name: 'app.root.h1.f1.horse_racing.horse_racing',
    url: '/horse-racing',
    params: {
      category: 'horseRacing',
    },
  },
  {
    name: 'app.root.h1.f1.horse_racing.harness',
    url: '/harness',
    params: {
      category: 'harness',
    },
  },
  {
    name: 'app.root.h1.f1.horse_racing.greyhound',
    url: '/greyhound',
    params: {
      category: 'greyhound',
    },
  },
  {
    name: 'app.root.h1.f1.horse_racing.next-to-jump',
    url: '/next-to-jump',
    params: {
      category: 'nextToJump',
    },
  },
  {
    name: 'app.root.h1.f1.promo',
    abstract: true,
    template: '<ui-view class="linda-view"></ui-view>',
  },
  {
    name: 'app.root.h1.f1.promo.list',
    url: '/promotions',
    template: '<linda-promos scroll-to-top></linda-promos>',
  },
  {
    name: 'app.root.h1.f1.promo.item',
    url: '/promotions/:name',
    template: '<linda-promo-page scroll-to-top></linda-promo-page>',
  },
  {
    name: 'app.root.h1.f1.promo.bonus',
    url: '/promotions/sport-welcome-bonus',
    template: '<linda-promo-page scroll-to-top></linda-promo-page>',
  },
  {
    name: 'app.root.h1.f1.tournament',
    abstract: true,
    template: '<ui-view class="linda-view" scroll-to-top></ui-view>',
  },
  {
    name: 'app.root.h1.f1.tournament.list',
    url: '/tournaments',
    template: '<batman-tournament-list1  players="true" competitors="5"></batman-tournament-list1>',
    params: {
      pageName: 'tournaments',
    },
  },
  {
    name: 'app.root.h1.f1.tournament.page',
    url: '/tournaments/:name',
    template: '<linda-tournament-page></linda-tournament-page>',
  },
  {
    name: 'app.root.h1.f1.spin-rally',
    url: '/spin-rally',
    template: '<batman-fast-tournaments-page1 scroll-to-top></batman-fast-tournaments-page1>',
    params: {
      eventId: '',
    },
  },
  {
    name: 'app.root.h1.f1.sportpage.home',
    url: '/?sportids&catids&champids&tabs&eventid',

    template: '',
    params: {
      page: 'prelive',
    },
  },
  {
    name: 'app.root.h1.casino',
    abstract: true,
    template: '<linda-view-layer-three scroll-to-top></linda-view-layer-three>',
  },
  {
    name: 'app.root.h1.casino-hall',
    abstract: true,
    template: '<linda-view-layer-four scroll-to-top></linda-view-layer-four>',
  },
  {
    name: 'app.root.h1.casino.provider',
    url: '/casino-providers/:name',
    template: '<batman-game-hall2 scroll-to-top></batman-game-hall2>',
    params: {
      casinoBonus: true,
    },
  },
  {
    name: 'app.root.h1.casino.favourites',
    url: '/favourites',
    template: '<batman-game-box-favourites2 scroll-to-top></batman-game-box-favourites2>',
    params: {
      collection: 'games',
    },
  },

  {
    name: 'app.root.h1.casino.last-played',
    url: '/continue-playing',
    private: true,
    template: '<batman-recently-played-list4 scroll-to-top><batman-recently-played-list4/>',
    params: {
      collection: 'games',
      name: 'last-played',
    },
  },
  {
    name: 'app.root.h1.f1.account',
    abstract: true,
    private: true,
    template: '<linda-profile-page scroll-to-top></linda-profile-page>',
  },
  {
    name: 'app.root.h1.f1.account.info',
    url: '/account',
    template: '<linda-profile-settings scroll-to-top></linda-profile-settings>',
  },
  {
    name: 'app.root.h1.f1.account.bonuses',
    url: '/account/your-bonuses',
    template: '<linda-profile-bonus-list scroll-to-top></linda-profile-bonus-list>',
  },
  {
    name: 'app.root.h1.f1.account.history',
    url: '/account/balance-history',
    template: '<linda-cashbox-balance-information scroll-to-top></linda-cashbox-balance-information>',
  },
  {
    name: 'app.root.h1.f1.achievement',
    url: '/weekly-achievement',
    template: '<linda-achievements-page scroll-to-top></linda-achievements-page>',
    params: {
      tab: 'sport',
    },
  },
  {
    name: 'app.root.h1.f1.account.verification',
    url: '/account/verification',
    private: true,
    template: '<dvs-page></dvs-page>',
  },
  {
    name: 'app.root.h1.f1.account.mybets',
    url: '/account/mybets',
    template: '<linda-sport-page scroll-to-top></linda-sport-page>',
    params: {
      page: 'mybets',
      title: true,
    },
  },
  {
    name: 'app.root.game',
    abstract: true,
    template: '<linda-game-page></linda-game-page>',
    gamePage: true,
  },
  {
    name: 'app.root.claw_games',
    abstract: true,
    private: true,
    template: '<ui-view></ui-view>',
  },
  {
    name: 'app.root.claw_games.gamepage',
    url: '/machine/:name',
    template: '<batman-view-layer1 />',
  },
  {
    name: 'app.root.game.real',
    url: '/play/:name',
    template: '',
    private: true,
    params: {
      mode: 'real',
    },
  },
  {
    name: 'app.root.game.demo',
    url: '/game/:name',
    template: '<linda-demo-game-footer game="game"></linda-demo-game-footer>',
    params: {
      mode: 'demo',
      casinoBonus: true,
    },
  },
  {
    name: 'app.root.missedData',
    url: '/missed-data',
    private: false,
    template: '<linda-missed-data-form></linda-missed-data-form>',
  },
  {
    name: 'app.root.h1.casino-hall.collection',
    url: '/:collection',
    template: `<batman-game-hall3></batman-game-hall3>`,
    params: {
      collection: 'games',
      casinoBonus: true,
    },
    onEnter: [
      'casinoResolver',
      function (casinoResolver) {
        casinoResolver.collection();
      },
    ],
  },
  {
    name: 'app.root.h1.casino.category',
    url: '/:collection/:category',
    template: '<batman-game-hall1></batman-game-hall1>',
    params: {
      collection: 'games',
      category: 'top',
      casinoBonus: true,
    },
    onEnter: [
      'casinoResolver',
      function (casinoResolver) {
        casinoResolver.collection();
      },
    ],
  },
  {
    name: 'app.root.h1.f1.seasons',
    url: '/seasons/:name',
    template: '<linda-promo-wrapper scroll-to-top></linda-promo-wrapper>',
  },
];

export { router };
