(function () {
  'use strict';

  const directive = { name: 'sportazaCheckedWb' };

  controller.$inject = ['$location', '$state'];

  function controller($location, $state) {
    const sportBonuses = [];
    const casinoBonuses = [];
    const userAffiliateBonus = $location.search();

    function link(scope, element, attrs) {
      function setBonus(collection) {
        scope.formData.trigger_codes = collection[0].trigger_codes;
        scope.activeBonus = collection[0].name;
        scope.activeBonusType = collection[0].type;
        scope.$parent.bonusSelect = collection[0].trigger_codes;
      }
      scope.$watchCollection('collection', function () {
        if (userAffiliateBonus.bonus && scope.collection.length) {
          const bonusForUser = scope.collection.filter((bonus) => {
            if (bonus.type === userAffiliateBonus.bonus) return bonus;
          });
          if (bonusForUser.length) {
            setBonus(bonusForUser);
          }
          return;
        }
        if (scope.collection.length && scope.collection[0].trigger_codes) {
          scope.collection.filter((bonus) => {
            if (bonus.type === 'sport') {
              sportBonuses.push(bonus);
              return;
            }
            casinoBonuses.push(bonus);
          });

          if ($state.params.casinoBonus) {
            setBonus(casinoBonuses);
            return;
          }
          setBonus(sportBonuses);
        }
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
