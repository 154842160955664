(function () {
  'use strict';
  const directive = { name: 'scrollToElement' };

  controller.$inject = ['$window'];

  function controller($window) {
    function link(scope, element, attrs) {
      const rect = element[0].getBoundingClientRect();
      const offset = rect.top + $window.scrollY;
      $window.scrollTo({
        top: offset,
        behavior: 'smooth',
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
