import '../../../service/domain/popup.js';
import '../../../service/domain/shop.js';
import '../../../service/rx/shop$.js';
import '../../../service/domain/user.js';
(function () {
  'use strict';

  controller.$inject = ['$scope', 'popup', 'shop', 'shop$', 'user'];

  function controller($scope, _popup, _shop, _shop$, _user) {
    $scope.collection = [];
    $scope.preloader = false;

    const options = {};

    $scope.buy = (confirm) => {
      if (_user.profile.coins < $scope.item.price) {
        if (_user.profile.balance === 0) {
          _popup.open({ name: 'shop-deposit', content: $scope.item });
        } else {
          _popup.open({ name: 'shop-play', content: $scope.item });
        }
      } else {
        _popup.open({
          name: confirm,
          content: $scope.item,
        });
      }
    };

    $scope.selectOffer = (offer) => {
      $scope.item = offer;
    };

    function getCollection(o) {
      $scope.preloader = true;
      _shop
        .list(o)
        .then(
          (a) => {
            $scope.collection = a.result;
            $scope.item = a.result[0];
          },
          (a) => {}
        )
        .finally(() => {
          $scope.preloader = false;
        });
    }

    this.$onChanges = () => {
      options.category = this.category;
      options.count = this.count || 25;
      if (this.group) {
        options.group = this.group;
      }

      getCollection(options);
    };
  }

  new Controller('shopGroupBox', controller, {
    category: '<',
    count: '<',
    group: '<',
  });
})();

(function () {
  'use strict';

  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanShopGroupBox${i}`, 'shopGroupBox');
  });
})();
