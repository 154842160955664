import '../../../service/tournaments/fast-tournament.js';
import '../../../service/domain/popup.js';
import {filter} from 'rxjs/operators';

(function () {
  'use strict';

  controller.$inject = ['$scope', 'fastTournament', 'popup'];

  function controller($scope, _fastTournament, _popup) {
    this.$onChanges = () => {
      $scope.tournament = this.item;
    };

    const subscriptionPreloader = _fastTournament.preloader$
      .pipe(filter((answer) => answer.action === 'subscribe'))
      .subscribe((answer) => {
        $scope.preloader = answer.preloader;
      });

    $scope.subscribe = (tournamentId) => {
      _fastTournament.subscribe(tournamentId, $scope.tournament.type)?.then(() => {
        $scope.tournament.optIn = true;
      });
    };

    this.$onDestroy = () => {
      subscriptionPreloader.unsubscribe();
    };
  }

  new Controller('fastTournamentsItem', controller, { item: '<' });
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].forEach((i) => {
    new Batman(`batmanFastTournamentsItem${i}`, 'fastTournamentsItem');
  });
})();
