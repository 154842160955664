import '../../../service/tournaments/fast-tournament.js';
import '../../../service/domain/user.js';
import '../../../service/rx/ws$.js';
import '../../../service/configs/ws-events.js';
import { Subject } from 'rxjs';
import { filter, takeUntil, throttleTime } from 'rxjs/operators';

(function () {
  'use strict';

  controller.$inject = ['$scope', 'fastTournament', 'user', 'ws$', 'wsEvents', 'user'];

  function controller($scope, _fastTournament, user, _ws$, _wsEvents, _user) {
    $scope.data = [];
    $scope.currentPlayerId = user?.profile?.account_id;
    const destroy$ = new Subject();
    const getListOfParticipants = () => {
      _fastTournament
        .getLeaderBoardList(this.tournamentId)
        .then((answer) => {
          $scope.data = answer.result;
        })
        .catch(() => {})
        .finally(() => {});
    };

    this.$onChanges = () => {
      getListOfParticipants();
    };

    _ws$
      .pipe(
        takeUntil(destroy$),
        throttleTime(5000),
        filter(({ event }) => event === _wsEvents.fastTournamentUpdateLeaderboard)
      )
      .subscribe(() => {
        getListOfParticipants();
      });

    this.$onDestroy = () => {
      destroy$.next();
      destroy$.unsubscribe();
    }
  }

  new Controller('fastTournamentsLeaderboard', controller, { tournamentId: '<' });
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanFastTournamentsLeaderboard${i}`, 'fastTournamentsLeaderboard');
  });
})();
