import '../../service/domain/http.js'
(function () {
  'use strict';

  controller.$inject = ['http', '$q'];

  function controller(_http, $q) {
    return {
      fastTournamentList(options) {
        let defer = $q.defer(),
          alias = 'fastTournamentList',
          config = {
            params: options,
            cache: false,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },

      fastTournamentHistory(options) {
        let defer = $q.defer(),
          alias = 'fastTournamentHistory',
          config = {
            params: options,
            cache: false,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },

      fastTournamentSubscribe(tournamentEventId) {
        let defer = $q.defer(),
          alias = 'fastTournamentSubscribe',
          data = {
            tournamentEventId,
          },
          config = {
            cache: false,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.post(alias, data, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },

      fastTournamentGetLeaderboard(tournamentEventId, options) {
        let defer = $q.defer(),
          alias = {
            name: 'fastTournamentGetLeaderboard',
            param: tournamentEventId,
          },
          config = {
            params: options,
            cache: false,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },
    };
  }

  app.factory('fastTournamentApi', controller);
})();
