import '../../service/domain/account.js';
import '../../service/domain/popup.js';
import '../../service/helper.js';
(function () {
  'use strict';

  const component = { name: 'lindaRecoveryLoginForm' };

  controller.$inject = ['$scope', 'account', 'popup', 'helper'];

  function controller($scope, _account, _popup, _helper) {
    $scope.preloader = false;

    $scope.formData = {
      email: '',
    };

    $scope.submit = _helper.form.validator((form) => {
      $scope.preloader = true;

      _account
        .recoveryLogin($scope.formData)
        .then((answer) => {
          _popup.close();
        })
        .finally((answer) => {
          $scope.preloader = false;
        });
    });
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
  });
})();
