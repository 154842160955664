import '../../../service/tournaments/fast-tournament.js';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

(function () {
  'use strict';

  controller.$inject = ['$scope', 'fastTournament'];

  function controller($scope, _fastTournament) {
    $scope.preloader = true;
    const destroy$ = new Subject();

    _fastTournament.getTournamentList().then((list) => {
      $scope.tournaments = { ...list };
    });

    _fastTournament.fastTournamentStore$.pipe(takeUntil(destroy$)).subscribe((answer) => {
      $scope.tournaments = { ...answer };
    });

    _fastTournament.preloader$
      .pipe(
        takeUntil(destroy$),
        filter((answer) => answer.action === 'listPreloader')
      )
      .subscribe((answer) => {
        $scope.preloader = answer.preloader;
      });

    this.$onDestroy = () => {
      destroy$.next();
      destroy$.unsubscribe();
    };
  }

  new Controller('fastTournamentWidget', controller, {});
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanFastTournamentWidget${i}`, 'fastTournamentWidget');
  });
})();
