import '../../../service/tournaments/fast-tournament.js';
import '../../../service/rx/ws$.js';
import '../../../service/domain/user.js';
import '../../../service/configs/ws-events.js';
import { Subject } from 'rxjs';
import { filter, takeUntil, throttleTime, take } from 'rxjs/operators';

(function () {
  'use strict';

  controller.$inject = ['$scope', 'fastTournament', 'ws$', 'user', 'wsEvents', '$interval'];

  function controller($scope, _fastTournament, _ws$, _user, _wsEvents, $interval) {
    let timer;
    let lastBalance = _user.profile.balance;

    const lastBonusBalance = _user.profile.bonus?.casino.sum;
    const destroy$ = new Subject();

    $scope.tournaments = {};
    $scope.preloader = true;

    $scope.showMinBetWarning = false;
    $scope.showBonusBetWarning = false;

    _ws$
      .pipe(
        takeUntil(destroy$),
        filter(({ event }) => event === _wsEvents.balanceUpdate)
      )
      .subscribe((message) => {
        if ($scope.tournaments.live.show) {
          const newBalance = parseFloat(message.data.balance);
          const newBonusBalance = parseFloat(message.data.bonus);
          const betAmount = Math.ceil((lastBalance - newBalance) * 100) / 100;
          const minAmount = parseFloat($scope.tournaments.live.minBetAmount || 2);
          lastBalance = newBalance;
          if (lastBonusBalance !== newBonusBalance) {
            $scope.showBonusBetWarning = true;
            return;
          }
          if (minAmount > betAmount && betAmount > 0) {
            $scope.showMinBetWarning = true;
          }
        }
      });

    const setTimer = (tournamentStart, tournamentEnd) => {
      let time = +new Date() / 1000;
      $interval.cancel(timer);
      timer = $interval(() => {
        if (time >= tournamentEnd / 1000) {
          $interval.cancel(timer);
          _fastTournament
            .getLeaderBoardList($scope.tournaments.live.tournamentEventId)
            .then((answer) => {
              if (answer.result.currentPlayer.place) {
                _fastTournament.openCurrentPopup($scope.tournaments.live, answer.result);
              }
            })
            .catch((error) => {})
            .finally(() => {});
          return;
        }
        time++;
      }, 1000);
    };

    function applyTournamentMeta(tournament) {
      _fastTournament
        .getLeaderBoardList(tournament.tournamentEventId)
        .then((answer) => {
          $scope.data = answer.result;
          setTimer(tournament.startTime, tournament.endTime);
        })
        .catch(() => {})
        .finally(() => {});
    }

    function fetchTournaments() {
      return _fastTournament.getTournamentList().then((list) => {
        $scope.tournaments = { ...list };
        if ($scope.tournaments.live.show) {
          applyTournamentMeta($scope.tournaments.live);
        }
      });
    }

    fetchTournaments();

    _ws$
      .pipe(
        takeUntil(destroy$),
        throttleTime(5000),
        filter(({ event }) => event === _wsEvents.fastTournamentUpdateLeaderboard)
      )
      .subscribe(({ data }) => {
        _fastTournament.getLeaderBoardList(data.tournamentId).then((answer) => {
          $scope.data = answer.result;
        });
      });

    _ws$
      .pipe(
        filter(({ event }) => event === _wsEvents.fastTournamentUpdateLeaderboard),
        take(1),
        takeUntil(destroy$),
      )
      .subscribe(() => {
        fetchTournaments();
      });

    this.$onDestroy = () => {
      destroy$.next();
      destroy$.unsubscribe();
      $interval.cancel(timer);
    };
  }

  new Controller('fastTournamentGame', controller, {});
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanFastTournamentGame${i}`, 'fastTournamentGame');
  });
})();
