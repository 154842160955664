import '../../../service/domain/deposit.js';
(function () {
  'use strict';
  controller.$inject = ['$scope', 'deposit'];

  function controller($scope, _deposit) {
    $scope.preloader = false;
    $scope.isQuickDeposit = false;

    $scope.paysystem = {};

    $scope.formData = {
      amount: 0,
      paymethod: '',
      paysystem_id: '',
    };

    $scope.selectCard = (cardIndex) => {
      $scope.selectedDeposit = $scope.depositCollection[cardIndex];
      $scope.formData.amount = $scope.depositCollection[cardIndex].paysystem.rules.default;

      $scope.paysystem = {
        ...$scope.depositCollection[cardIndex].paysystem,
        amount:
          typeof $scope.formData.amount === 'string'
            ? $scope.formData.amount.split('.')[0] * 1
            : $scope.formData.amount,
        depositIcon: $scope.depositCollection[cardIndex].icon,
      };
    };

    this.$onInit = () => {
      $scope.preloader = true;

      _deposit
        .collection({})
        .then((a) => {
          if (a.result.length) {
            const amount =
              typeof a.result[0].last_transaction.amount === 'string'
                ? a.result[0].last_transaction.amount.split('.')[0] * 1
                : a.result[0].last_transaction.amount;

            $scope.depositCollection = a.result;
            $scope.selectCard(0);
            $scope.paysystem = a.result[0].paysystem;
            $scope.formData.amount = amount;
            $scope.paysystem.amount = amount;
            $scope.paysystem.depositIcon = a.result[0].icon;
            $scope.isQuickDeposit = true;
          }
        })
        .catch((e) => {})
        .finally(() => {
          $scope.preloader = false;
        });
    };
  }

  new Controller('multipleQuickDeposit', controller);
})();
(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanMultipleQuickDeposit${i}`, 'multipleQuickDeposit');
  });
})();
