import '../../service/api/fast-tournament-api.js'
import '../../service/domain/remembered.js';
import '../../service/tournaments/fast-tournament-store.js'
import '../../service/tournaments/fast-tournament-mapper.js'
import { Subject } from 'rxjs';

(function () {
  'use strict';

  controller.$inject = ['fastTournamentApi', 'fastTournamentStore', 'fastTournamentMapper', 'user', 'popup', 'remembered'];

  function controller(_fastTournamentApi, _fastTournamentStore, _fastTournamentMapper, _user, _popup, _remembered) {
    const fastTournamentStore$ = new Subject();
    const preloader$ = new Subject();
    let tournaments;

    const getTournamentList = () => {
      tournaments = _fastTournamentStore.createStore();
      const mapper = _fastTournamentMapper.createMapper;

      return new Promise((resolve, reject) => {
        preloader$.next({ action: 'listPreloader', preloader: true });

        _fastTournamentApi
          .fastTournamentList()
          .then(function (list) {
            list.result.forEach((item) => tournaments.add(mapper(item)));
            resolve(tournaments);
          })
          .catch(() => {
            reject();
          })
          .finally(() => {
            preloader$.next({ action: 'listPreloader', preloader: false });
          });
      });
    };

    function subscribeOnTournament(tournamentId, type) {
      return new Promise((resolve, reject) => {
        _fastTournamentApi
          .fastTournamentSubscribe(tournamentId)
          .then(() => {
            preloader$.next({ action: 'itemPreloader', preloader: true });
            tournaments.updateOptIn(tournamentId, type);
            fastTournamentStore$.next(tournaments);
            resolve();
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            preloader$.next({ action: 'itemPreloader', preloader: false });
          });
      });
    }

    const subscribe = (tournamentId, type) => {
      if (!_user.status) {
        _popup.open({
          name: _remembered.login ? 'login' : 'registration',
          cb: () => {
            return subscribeOnTournament(tournamentId, type);
          },
        });
        return;
      }
      return subscribeOnTournament(tournamentId, type);
    };

    const getLeaderBoardList = (tournamentId) => {
      return new Promise((resolve, reject) => {
        _fastTournamentApi
          .fastTournamentGetLeaderboard(tournamentId)
          .then((answer) => {
            resolve(answer);
          })
          .catch((e) => {
            reject(e);
          })
          .finally(() => {});
      });
    };

    const getHistory = (limit) => {
      return new Promise((resolve, reject) => {
        _fastTournamentApi
          .fastTournamentHistory({ limit })
          .then(function (answer) {
            preloader$.next({ action: 'historyPreloader', preloader: true });
            resolve(answer);
          })
          .catch((error) => {
            reject();
          })
          .finally(() => {
            preloader$.next({ action: 'historyPreloader', preloader: false });
          });
      });
    };

    const openCurrentPopup = (liveTournament, leaderBoard) => {
      if (leaderBoard.leaderBoard.length >= liveTournament.minPlayersQty) {
        const nextTournament = tournaments.upNext.show ? tournaments.upNext : {};
        const afterWinPopupContent = Object.assign({}, leaderBoard, { tournament: nextTournament });

        setTimeout(() => {
          _popup.open({ name: 'after-win-rally', content: afterWinPopupContent });
        }, 5000);
      } else {
        _popup.open({ name: 'message-min-user' });
      }
    };

    return {
      getTournamentList,
      subscribe,
      getLeaderBoardList,
      getHistory,
      openCurrentPopup,
      fastTournamentStore$,
      preloader$,
    };
  }

  app.factory('fastTournament', controller);
})();
