const aliases = {
  home: {
    page: true,
    name: 'app.root.h1.f1.home',
  },
  deposit: {
    page: false,
    name: 'cashbox',
    private: true,
  },
  withdrawal: {
    page: false,
    name: 'cashbox',
    content: { tab1: 2, tab2: 1 },
  },
  cryptoPopup: {
    private: true,
    page: false,
    name: 'cashbox',
    content: { tab1: 'crypto' },
  },
  login: {
    page: false,
    name: 'login',
  },
  registration: {
    page: false,
    name: 'registration',
  },
  fastRegistration: {
    page: true,
    name: 'app.root.h1.f1.paynplay',
  },
  promo: {
    page: true,
    name: 'app.root.h1.f1.promo.list',
  },
  promos: {
    page: true,
    name: 'app.root.h1.f1.promo.item',
  },
  realGame: {
    page: true,
    name: 'app.root.game.real',
  },
  tournaments: {
    page: true,
    name: 'app.root.h1.f1.tournament.list',
  },
  profile: {
    page: true,
    name: 'app.root.h1.f1.account.info',
  },
  paynplay: {
    page: true,
    name: 'app.root.h1.f1.paynplay',
  },
  gameHall: {
    page: true,
    name: 'app.root.h1.casino-hall.collection',
    params: {
      collection: 'games',
    },
  },
  collection: {
    page: true,
    name: 'app.root.h1.f1.collection.list',
  },
  cardcollection: {
    page: true,
    name: 'app.root.h1.f1.collection.list',
  },
  cardCollection: {
    page: true,
    name: 'app.root.h1.f1.collection.list',
  },
  bet: {
    private: true,
    page: true,
    name: 'app.root.h1.f1.sportpage.live',
  },
  soccer: {
    name: 'app.root.h1.f1.sportpage.prelive',
    page: true,
    params: {
      sportids: 66,
    },
  },
  tennis: {
    name: 'app.root.h1.f1.sportpage.prelive',
    page: true,
    params: {
      sportids: 68,
    },
  },
  sport: {
    page: true,
    name: 'app.root.h1.f1.sportpage.prelive',
  },
  live: {
    page: true,
    name: 'app.root.h1.casino.category',
    params: {
      collection: 'games',
      category: 'live-casino',
    },
  },
  games: {
    page: true,
    name: 'app.root.h1.casino-hall.collection',
    params: {
      collection: 'games',
    },
  },
  missedData: {
    page: true,
    name: 'app.root.missedData',
  },
  notFound: {
    page: true,
    name: 'app.root.h1.f1.404',
  },
  sportEvent: {
    page: true,
    name: 'app.root.h1.f1.sportpage.prelive',
  },
  shop: {
    page: true,
    name: 'app.root.h1.f1.shop',
  },
  challenge: {
    page: true,
    name: 'app.root.h1.f1.achievement',
  },
  cup: {
    page: true,
    name: 'app.root.h1.f1.cup',
  },

  HoRacing: {
    page: true,
    name: 'app.root.h1.f1.horse_racing.horse_racing',
  },
  HaRacing: {
    page: true,
    name: 'app.root.h1.f1.horse_racing.harness',
  },
  GreyRacing: {
    page: true,
    name: 'app.root.h1.f1.horse_racing.greyhound',
  },
  NextToJump: {
    page: true,
    name: 'app.root.h1.f1.horse_racing.next-to-jump',
  },

  // ================= aliases for horse racing
  leftlink1: {
    page: true,
    name: 'app.root.h1.f1.sporttournament',
  },
  leftlink2: {
    page: true,
    name: 'app.root.h1.f1.collection.list',
  },
  leftlink3: {
    page: true,
    name: 'app.root.h1.f1.achievement',
  },
  leftlink4: {
    page: true,
    name: 'app.root.h1.f1.cup',
  },

  rightlink1: {
    page: true,
    name: 'app.root.h1.casino.category',
    params: {
      collection: 'games',
      category: 'live-games',
    },
  },
  rightlink2: {
    page: true,
    name: 'app.root.h1.casino.category',
    params: {
      collection: 'games',
      category: 'top',
    },
  },
  rightlink3: {
    page: true,
    name: 'app.root.h1.casino.category',
    params: {
      collection: 'games',
      category: 'slots',
    },
  },
  rightlink4: {
    page: true,
    name: 'app.root.h1.casino.category',
    params: {
      collection: 'games',
      category: 'popular',
    },
  },

  bonus_crab: {
    page: true,
    name: 'app.root.claw_games.gamepage',
    params: { name: 'cash-crab' },
  },
  oktoberfest: {
    page: true,
    name: 'app.root.claw_games.gamepage',
    params: { name: 'oktoberfest' },
  },
  cash_crab: {
    page: true,
    name: 'app.root.claw_games.gamepage',
    params: { name: 'cash_crab' },
  },
  claw: {
    page: true,
    name: 'app.root.claw_games.gamepage',
  },

  bonuses: {
    page: true,
    name: 'app.root.h1.f1.account.bonuses',
  },
  terms: {
    page: true,
    name: 'app.root.h1.info.terms',
  },
  gameProvider: {
    page: true,
    name: 'app.root.h1.casino.provider',
  },
  evolution: {
    page: true,
    name: 'app.root.h1.casino.provider',
    params: {
      name: 'evolution',
    },
  },
  pragmatic: {
    page: true,
    name: 'app.root.h1.casino.provider',
    params: {
      name: 'pragmatic',
    },
  },
  threeoaks: {
    page: true,
    name: 'app.root.h1.casino.provider',
    params: {
      name: 'threeoaks',
    },
  },
  spinomenal: {
    page: true,
    name: 'app.root.h1.casino.provider',
    params: {
      name: 'spinomenal',
    },
  },
  ela: {
    page: true,
    name: 'app.root.h1.casino.provider',
    params: {
      name: 'ela',
    },
  },
  playngo: {
    page: true,
    name: 'app.root.h1.casino.provider',
    params: {
      name: 'playngo',
    },
  },
  relax: {
    page: true,
    name: 'app.root.h1.casino.provider',
    params: {
      name: 'relax',
    },
  },
  skywind: {
    page: true,
    name: 'app.root.h1.casino.provider',
    params: {
      name: 'skywind',
    },
  },
  netent: {
    page: true,
    name: 'app.root.h1.casino.provider',
    params: {
      name: 'netent',
    },
  },
  playtech: {
    page: true,
    name: 'app.root.h1.casino.provider',
    params: {
      name: 'playtech',
    },
  },
  quickspin: {
    page: true,
    name: 'app.root.h1.casino.provider',
    params: {
      name: 'quickspin',
    },
  },
  redtiger: {
    page: true,
    name: 'app.root.h1.casino.provider',
    params: {
      name: 'redtiger',
    },
  },
  hacksaw: {
    page: true,
    name: 'app.root.h1.casino.provider',
    params: {
      name: 'hacksaw',
    },
  },
  pushgaming: {
    page: true,
    name: 'app.root.h1.casino.provider',
    params: {
      name: 'pushgaming',
    },
  },
  halloween: {
    page: true,
    name: 'app.root.h1.f1.tournament.page',
    params: {
      name: 'halloween',
    },
  },
  // CHRISTMAS
  // promotions
  promotions: {
    page: true,
    name: 'app.root.h1.f1.promo.list',
  },
  // tournament
  christmasTournament: {
    page: true,
    name: 'app.root.h1.f1.tournament.page',
    params: {
      name: 'party-raffle',
    },
  },
  promoTennis: {
    page: true,
    private: true,
    name: 'app.root.h1.f1.sportpage.prelive',
    params: {
      champids: '3158',
    },
  },
  easter: {
    page: true,
    name: 'app.root.h1.f1.seasons',
    params: {
      name: 'easter',
    },
  },
};

export { aliases };
